.confirm-button {
    background-color: red !important;
  }

  .title {
    font-size: 24px !important;
    font-family: 'Sofia Pro';
    font-weight: 300;
    color: black;
  }

  .icon {
    background-color: rgba(203, 54, 54, 0.12);
    width: 100px;
    height: 100px;
    border: none;
  }

  .icon[alt="recall"] {
    background-color: white;
  }

  .swal2-html-container {
    font-family: 'Sofia Pro';
    font-size: 22px !important;
  }

  .container .swal2-popup {
    width: 556px;
    height: 421px;
  }

  .confirm-button .swal2-icon {
    border: none !important;
    background-color: red !important;
  }

  .swal2-actions .swal2-confirm {
    background-color: #023061 !important;
    color: #fff !important;
    padding: 13px 41px;
  }

  .swal2-actions .swal2-cancel {
    box-shadow: none !important;
    background-color: #DFF0FF !important;
    color: #000;
    padding: 13px 41px;
  }

  .swal2-confirm:focus, .swal2-cancel:focus {
    box-shadow: none !important;
    border: none !important;
  }

  .swal2-actions button:hover {
    border: none !important;
  }

  .swal2-actions {
    margin-bottom: 40px;
  }