@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: local('Roboto'),
    url(../assets/fonts/roboto/Roboto-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/sofia-pro/SofiaProLight.woff2) format('woff2');
}

@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/circular-std/CircularStd-Bold.woff2) format('woff2');
}
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/circular-std/CircularStd-Book.woff2) format('woff2');
}
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: url(../assets/fonts/circular-std/CircularStd-Black.woff2) format('woff2');
}
